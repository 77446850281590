export const pickerOptions = [
  {
    text: "今天",
    onClick(picker) {
      const end = new Date();
      const start = new Date(new Date().toDateString());
      end.setTime(start.getTime());
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "最近一周",
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "最近一个月",
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "最近三个月",
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit("pick", [start, end]);
    },
  },
];

export const operations = [
  {
    name: "设置",
    index: "operation",
    icon: "s-tools",
    items: [
      {
        name: "个人信息",
        index: "userinfo",
      },
      {
        name: "绑定手机号",
        index: "bindPhone",
      },
      {
        name: "修改密码",
        index: "resetpwd",
      },
      {
        name: "忘记密码",
        index: "forgetpwd",
      },
      {
        name: "退出登录",
        index: "logout",
      },
    ],
  },
];

export const msgs = [
  {
    name: "消息",
    index: "messages",
    icon: "s-comment",
    items: [
      {
        name: "未读消息",
        index: "unread",
      },
      {
        name: "已读消息",
        index: "readed",
      },
    ],
  },
];

export const statusList = [
  {
    key: 0,
    name: "待确认",
  },
  {
    key: 1,
    name: "已确认",
  },
  {
    key: 2,
    name: "已完成",
  },
  {
    key: 3,
    name: "已驳回",
  },
  {
    key: 4,
    name: "驳回申请还款",
  },
  {
    key: 5,
    name: "驳回延期",
  },
  {
    key: 6,
    name: "取消",
  },
  {
    key: 7,
    name: "申请延期",
  },
  {
    key: 8,
    name: "已延期",
  },
  {
    key: 9,
    name: "申请还款",
  },
];

// 0.未审核 1.待审核 2.审核失败 3. 审核通过
export const userStatusList = [
  {
    key: 0,
    name: "未审核",
  },
  {
    key: 1,
    name: "待审核",
  },
  {
    key: 2,
    name: "审核失败",
  },
  {
    key: 3,
    name: "审核通过",
  },
];

// 支付方式 WECHAT-微信，ALIPAY-支付宝
export const payTypes = [
  {
    id: "ALIPAY",
    name: "支付宝",
    remark: "请使用手机支付宝扫码支付",
    icon: require("@/assets/images/icon/alipay.png"),
  },
  {
    id: "WECHAT",
    name: "微信",
    remark: "请使用手机微信扫码支付",
    icon: require("@/assets/images/icon/wechat.png"),
  },
];

// 借款人类型，1为出借人，2为借款人
export const personTypes = [
  {
    id: 1,
    name: "出借人",
  },
  {
    id: 2,
    name: "借款人",
  },
];
