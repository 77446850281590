<template>
  <div
    :class="['my-data-panel', { 'my-data-panel_checked': checked }]"
    @click="$emit('click')"
  >
    <span :class="['my-data-panel_mark', checkedClass]" v-if="checked">
      <i class="el-icon-finished"></i>
    </span>
    <slot name="header"></slot>
    <div class="my-data-panel_body flex-box flex-between">
      <div class="icon-box">
        <i
          :class="['icon-box_icon el-icon-' + (icon || 'coin')]"
          :style="{ color: color || '#03a9f4' }"
          v-if="!$slots.icon"
        ></i>
        <slot name="icon"></slot>
      </div>
      <div class="data-box flex-box flex-column">
        <span class="data-box_title">{{ title }}</span>
        <span class="data-box_data">
          {{ num }} <em> {{ unit }} </em>
        </span>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "MyDataPanel",
  props: ["icon", "color", "title", "num", "unit", "checked", "checkedClass"],
  data() {
    return {
      isHover: false,
    };
  },
};
</script>

<style lang="less">
.my-data-panel {
  width: 100%;
  margin: 15px 0;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(161, 211, 240, 0.2);

  &_checked {
    border: 2px solid #2196f3;
  }

  &_mark {
    position: absolute;
    right: 0;
    top: 0;
    background: #2196f3;
    padding: 0px 6px;
    border-bottom-left-radius: 8px;
  }

  .icon-box {
    &_icon {
      font-size: 32px;
    }
  }

  &_body {
    padding: 15px;

    .data-box {
      &_title {
        text-align: right;
        margin-bottom: 10px;
        color: #607d8b;
        font-weight: 600;
        font-size: 18px;
      }

      &_data {
        text-align: right;
        color: #ff9800;
        font-size: 16px;
        font-weight: 600;

        em {
          color: #607d8b;
          font-size: 12px;
          font-style: normal;
        }
      }
    }
  }
}
</style>
