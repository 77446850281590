import { Message, MessageBox } from "element-ui";
import { hasProperty } from "../common/utils";
import MyItem from "./base/my-item";
import MyBacktop from "./base/my-backtop";
import MyDataPanel from "./base/my-data-panel";

const comps = {
  MyItem,
  MyBacktop,
  MyDataPanel,
};

const install = (_Vue) => {
  for (const key in comps) {
    if (hasProperty(comps, key)) {
      _Vue.component(key, comps[key]);
    }
  }
  _Vue.prototype.$message = Message;
  _Vue.prototype.$messageBox = MessageBox;
};

export default { install };
