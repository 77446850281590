<template>
  <div :class="classObj" class="app-wrapper">
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <navbar />
      <tags-view />
      <app-main />
    </div>

    <el-button class="btn-plus" type="danger" circle @click="handleAddReceipt">
      <el-tooltip effect="light" content="打借条" placement="top">
        <i class="el-icon-plus"></i>
      </el-tooltip>
    </el-button>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, TagsView } from "./components";

export default {
  name: "Layout",
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView,
  },
  computed: {
    sidebar(){
      return this.$store.getters.sidebar;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.openedwithoutAnimation,
      };
    },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("closeSideBar", { withoutAnimation: false });
    },
    handleAddReceipt() {
      this.$bus.$emit("addReceipt");
    },
  },
};
</script>

<style lang="less">
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }

  .btn-plus {
    position: fixed;
    right: 50px;
    bottom: 120px;
    z-index: 10;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
</style>
