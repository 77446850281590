import Layout from "@/views/layout/Layout";

export default [
  {
    path: '/',
    redirect: '/digit/digitInfo'
  },
  {
    path: "/digit",
    name: "Digit",
    redirect: 'noredirect',
    alwaysShow: true,
    meta: {
      title: "数据中心",
      icon: 'data'
    },
    component: Layout,
    children: [
      {
        path: "/digit/digitInfo",
        name: "DigitInfo",
        meta: {
          title: "数字总概",
        },
        component: () =>
          import(/* webpackChunkName: "digitInfo" */ "@/views/digit/digitInfo"),
      },
      {
        path: "/digit/borrowInfo",
        name: "BorrowInfo",
        meta: {
          title: "借入信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "borrowInfo" */ "@/views/digit/borrowInfo"
          ),
      },
      {
        path: "/digit/lendInfo",
        name: "LendInfo",
        meta: {
          title: "借出信息",
        },
        component: () =>
          import(/* webpackChunkName: "lendInfo" */ "@/views/digit/lendInfo"),
      },
    ],
  },
  {
    path: "/jietiao",
    name: "Jietiao",
    redirect: 'noredirect',
    alwaysShow: true,
    meta: {
      title: "借条管理",
      icon: 'jietiao'
    },
    component: Layout,
    children: [
      {
        path: "/jietiao/unconfirmed",
        name: "Unconfirmed",
        meta: {
          title: "待确认借条",
        },
        component: () =>
          import(
            /* webpackChunkName: "unconfirmed" */ "@/views/jietiao/unconfirmed"
          ),
      },
      {
        path: "/jietiao/unReceived",
        name: "UnReceived",
        meta: {
          title: "待收借条",
        },
        component: () =>
          import(
            /* webpackChunkName: "unReceived" */ "@/views/jietiao/unReceived"
          ),
      },
      {
        path: "/jietiao/unBack",
        name: "UnBack",
        meta: {
          title: "待还借条",
        },
        component: () =>
          import(/* webpackChunkName: "unBack" */ "@/views/jietiao/unBack"),
      },
      {
        path: "/jietiao/overdue",
        name: "Overdue",
        meta: {
          title: "逾期借条",
        },
        component: () =>
          import(/* webpackChunkName: "overdue" */ "@/views/jietiao/overdue"),
      },
    ],
  },
  {
    path: "/credit",
    component: Layout,
    meta: {
      title: "信用查询",
      noCache: true,
      icon: 'credit'
    },
    redirect: '/credit',
    children: [
      {
        path: '/credit',
        name: 'Credit',
        component: () => import(/* webpackChunkName: "credit" */ "@/views/credit"),
        meta: {
          title: "信用查询",
          noCache: true
        },
      }
    ]
  },
];
