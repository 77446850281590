<template>
  <div id="main" class="contract-template">
    <h2 style="text-align: center; margin-top: 20px">存证管家借款协议</h2>
    <!--甲-->
    <ul style="text-indent: 0">
      <li>甲方（借款人）：借款人姓名</li>
      <li>注册手机号：出借人手机号</li>
      <li>身份证号：出借人身份证号</li>
    </ul>
    <!--乙-->
    <ul style="text-indent: 0">
      <li>乙方（出借人）：出借人姓名</li>
      <li>注册手机号：出借人手机号</li>
      <li>身份证号：出借人身份证号</li>
    </ul>
    <!--丙-->
    <ul style="text-indent: 0">
      <li>丙方（居间服务商）：存证管家平台（隶属于存证管家技术有限公司）</li>
    </ul>
    <p>
      居间平台服务商（本合同丙方）为存证管家平台（微信公众号“存证管家”）的运营管理人，为借贷双方提供居间服务。
    </p>
    <p>
      甲乙双方均已在丙方平台注册，同意遵守丙方平台的各项行为准则，自愿授权平台查询其芝麻分，并同意将此芝麻分作为自己的信用供其它用户查询使用。
      在充分阅读理解本文本情形下，本着诚信自愿原则签订本《借款协议》。本协议内容如下：
    </p>
    <h2>一、借款主要内容</h2>
    <ul style="text-indent: 0">
      <li>
        借款金额：人民币 <u style="color: red">10000</u> 元，大写：<span
          style="color: red"
          >壹仟圆整</span
        >
      </li>
      <li>借款日期：2019-03-12</li>
      <li>还款日期：2019-03-18</li>
      <li>年化利率：<u style="color: red">3%</u></li>
      <li>借款用途：创业</li>
    </ul>
    <p>
      注：1.还款总额=借款金额*（1+借款时长/365*年化利率），其中借款时长：一周按7天计，一个月按30天计，一年按365天计。
    </p>
    <h2>二、协议的生效</h2>
    <ul>
      <li>
        甲方在丙方存证管家平台发起求借款申请，完善信息后点击发送按钮，即代表承认借条中约定的借款金额、起借日期、还款日期、年化利率等信息。另外，如果选择公开求借款选项，甲方的微信好友都有可能看到该借款信息。默认筹款时间72个小时，72小时之内甲方有权利随时关闭该借款，但已经接到的款项要按照约定如期、足额偿还；如果借款发起72个小时或者借款满标后，借款将自动关闭。
      </li>
      <li>
        乙方可通过线上或者线下的方式将资金出借给甲方：所谓线上出借是指乙方通过丙方存证管家的账户系统将银行卡中资金出借到甲方的账户余额中，但丙方在资金到账时要收取8‰（千分之八）的服务费。如果乙方通过线上方式出借，在资金到账的一刻，本协议生效。
      </li>
      <li>
        乙方也可通过支付宝、微信或现金等方式将资金出借给甲方，但乙方需要在存证管家平台上发出一个出借通知，甲方在收到通知且核实已收到款后，会将借条确认，确认后本协议即生效。
      </li>
    </ul>
    <h2>三、本息偿还方式</h2>
    <ul>
      <li>
        甲方必须按钮本协议的约定按时、足额偿还乙方的本金和利息。还款日不受法定假日或公休日的影响，还款日前必须还款，否则会对借款人的信用记录造成影响。
      </li>
      <li>
        甲方可以通过线上或者线下的方式予以还款，线上是指甲方通过存证管家的账户系统将待还本息偿还到乙方的账户余额中，此环节不再收取服务费用。
      </li>
      <li>
        如果甲方通过支付宝、微信或现金等方式将待还本息偿还给了乙方，甲方需要在存证管家平台上发起一个已还款提醒，乙方在收到该消息且核实已收到甲方的线下还款后，则可点击确认按钮以终结借条。如果乙方核实后发现没有收到甲方的线下还款，可以将该消息驳回或致电问询。
      </li>
      <li>
        甲方应还的本息总额计算公式为：待还本息=借款金额*（1+借款时长/365）*年化利率，其中借款时长为起借日期和还款日期间的天数。甲方可多次还款直至待还本息全部还清，但甲方的提前还款并不减少待还本息。
      </li>
    </ul>
    <h2>四、违约</h2>
    <ul>
      <li>
        甲乙双方需保证其提供的信息和资料的真实性，不得提供虚假资料或隐瞒重要事实。提供虚假资料或者故意隐瞒重要事实的，构成违约，应承担违约责任；构成犯罪的，丙方将有权向相关国家机关报案，追究其刑事责任。
      </li>
      <li>
        如果甲方没有按照约定按时、足额偿还对乙方的本金和利息，则甲方构成违约责任。
      </li>
      <li>
        丙方作为信息中介，不承担对乙方未收回本息部分的代偿责任。
        丙方拥有将甲方违约失信的相关信息在媒体披露的权利，并且丙方会将甲方违约信息录入存证管家平台黑名单以供他人查阅。
      </li>
    </ul>
    <h2>五、其他</h2>
    <ul>
      <li>
        本协议经甲乙双方通过丙方存证管家平台以网络在线点击确认的方式签订。各方委托存证管家平台保管所有与本协议有关的书面文件或电子信息。
      </li>
      <li>
        各方均确认，本协议的签订、生效和履行以不违反法律为前提。如果本协议中的任何一条或多条违反适用的法律，则该条将被视为无效，但该无效条款并不影响本协议其他条款的效力。
      </li>
      <li>
        本协议中所使用的定义，除非另有规定，否则应适用存证管家平台释义规则，存证管家平台对本文定义享有最终解释权。
      </li>
      <li>
        签订日期： 2019-03-12
        <img width="25%" :src="seal" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      seal: require("@/assets/images/seal.jpg"),
    };
  },
  created() {
    this.addWaterMarker("协议模板");
  },
  methods: {
    addWaterMarker(str) {
      this.$nextTick(() => {
        var can = document.createElement("canvas");
        var body = document.getElementById("main");
        body.appendChild(can);
        can.width = 400;
        can.height = 200;
        can.style.display = "none";
        var cans = can.getContext("2d");
        cans.rotate((-20 * Math.PI) / 180);
        cans.font = "32px Microsoft JhengHei";
        cans.fillStyle = "rgba(0, 0, 0, 0.2)";
        cans.textAlign = "left";
        cans.textBaseline = "Middle";
        cans.fillText(str, can.width / 4, can.height);
        body.style.backgroundImage = "url(" + can.toDataURL("image/png") + ")";
      });
    },
  },
};
</script>

<style lang="less">
.contract-template {
  height: 60vh;
  overflow: auto;
  padding: 0 20px;
}
</style>
