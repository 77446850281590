import request from "@/common/request";

export function delayJietiao(data) {
  return request({
    method: "post",
    params: {
      _gp: "pc.jietiao",
      _mt: "applyDelayJietiao",
      ...data,
    },
  });
}

export function newJietiao(data) {
  return request({
    method: "post",
    params: {
      _gp: "pc.jietiao",
      _mt: "newJietiao",
      ...data,
    },
  });
}

export function getJietiaoInfo(id) {
  return request({
    method: "get",
    params: {
      _gp: "pc.jietiao",
      _mt: "getJietiaoInfo",
      id,
    },
  });
}

export function operateJietiao(data) {
  return request({
    method: "post",
    params: {
      _gp: "pc.jietiao",
      _mt: "operateJietiao",
      ...data,
    },
  });
}

// 申请延期
export function applyDelayJietiao(data) {
  return request({
    method: "post",
    params: {
      _gp: "pc.jietiao",
      _mt: "applyDelayJietiao",
      ...data,
    },
  });
}

export function listByOverdue(searchName) {
  return request({
    method: "get",
    params: {
      _gp: "pc.record.jietiao",
      _mt: "listByOverdue",
      searchName,
    },
  });
}

export function listByUnBack(searchName) {
  return request({
    method: "get",
    params: {
      _gp: "pc.record.jietiao",
      _mt: "listByUnBack",
      searchName,
    },
  });
}

export function listByUnReceived(searchName) {
  return request({
    method: "get",
    params: {
      _gp: "pc.record.jietiao",
      _mt: "listByUnReceived",
      searchName,
    },
  });
}

export function listChujieByUnconfirmed(userId) {
  return request({
    method: "get",
    params: {
      _gp: "pc.record.jietiao",
      _mt: "listChujieByUnconfirmed",
      userId,
    },
  });
}
