const formatDate = (fdate, formatStr = "y-m-d h:i:s") => {
  //日期格式化
  if (fdate) {
    if (~fdate.toString().indexOf(".")) {
      fdate = fdate.substring(0, fdate.indexOf("."));
    }
    if (typeof fdate === "string") {
      fdate = fdate.toString().replace("T", " ").replace(/-/g, "/");
    }
    var fTime,
      fStr = "ymdhis";
    if (fdate) {
      fTime = new Date(fdate);
    } else {
      fTime = new Date();
    }
    var month = fTime.getMonth() + 1;
    var day = fTime.getDate();
    var hours = fTime.getHours();
    var minu = fTime.getMinutes();
    var second = fTime.getSeconds();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    hours = hours < 10 ? "0" + hours : hours;
    minu = minu < 10 ? "0" + minu : minu;
    second = second < 10 ? "0" + second : second;
    var formatArr = [
      fTime.getFullYear().toString(),
      month.toString(),
      day.toString(),
      hours.toString(),
      minu.toString(),
      second.toString(),
    ];
    for (var i = 0; i < formatArr.length; i++) {
      formatStr = formatStr.replace(fStr.charAt(i), formatArr[i]);
    }
    return formatStr;
  } else {
    return "";
  }
};

/**
 * 判断key是obj的自有属性
 * @param {*} obj
 * @param {*} key
 * @returns
 */
const hasProperty = (obj, key) => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};

/**
 * 判断字符串是空值，可识别undefined、null、''、[]
 * @param {*} str
 * @returns
 */
const isEmpty = (str) => {
  if (str === undefined || str === null) {
    return true;
  }
  if (str.toString()) {
    return true;
  }
  return false;
};

/**
 * 防抖
 * @param {*} func
 * @param {*} wait
 * @param {*} immediate
 * @returns
 */
const debounce = (func, wait, immediate) => {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
};

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
const deepClone = (source) => {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "shallowClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
};

const cleanArray = (actual) => {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
};

/**
 * json对象转url参数
 * @param {*} json
 * @returns
 */
const param = (json) => {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return "";
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
};

const json2FormData = (json) => {
  let formData = new FormData();
  for (const key in json) {
    if (hasProperty(json, key)) {
      formData.append(key, json[key]);
    }
  }
};

const getItemByKey = (value, list = [], key = "key") => {
  return list.find((item) => item[key] === value) || {};
};

const isExternal = (path) => {
  return /^(https?:|mailto:|tel:)/.test(path);
};

export {
  formatDate,
  hasProperty,
  isEmpty,
  debounce,
  deepClone,
  param,
  json2FormData,
  getItemByKey,
  isExternal,
};
