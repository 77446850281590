<template>
  <div class="message">
    <el-tabs v-model="formData.isRead" @tab-click="handleClick">
      <el-tab-pane label="未读消息" name="0"></el-tab-pane>
      <el-tab-pane label="已读消息" name="1"></el-tab-pane>
    </el-tabs>

    <el-table stripe height="500px" :data="listData">
      <el-table-column
        type="index"
        width="80"
        :index="indexMethod"
        label="序号"
      >
      </el-table-column>
      <el-table-column prop="title" label="标题" width="100"> </el-table-column>
      <el-table-column prop="sendName" label="发送人" width="80">
      </el-table-column>
      <el-table-column prop="content" label="内容" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="createTime" label="时间" width="180">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="operate" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            v-if="scope.row.isRead === 0"
            @click="handleRead(scope.row)"
          >
            标为已读
          </el-button>
          <el-button
            type="text"
            v-if="scope.row.isRead === 1"
            @click="handleDel(scope.row)"
          >
            删除
          </el-button>
          <el-button type="text" @click="handleDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="text-right" style="margin-top: 15px">
      <el-pagination
        :page-size="limit"
        :total="totalCount"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { listByPage, delMsg, marked } from "@/api/msg";
import { formatDate } from "@/common/utils";

export default {
  data() {
    return {
      formData: {
        isRead: "0",
      },
      page: 1,
      limit: 10,
      totalCount: 0,
      listData: [],
    };
  },
  created() {
    this.handleQueryData();
  },
  methods: {
    formatDate,
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.handleQueryData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.handleQueryData();
    },
    handleQueryData() {
      let param = {
        isRead: this.formData.isRead,
        page: this.page,
        limit: this.limit,
      };

      listByPage(param).then((res) => {
        this.totalCount = res.data.total;
        this.listData = res.data.items;
      });
    },
    handleClick() {
      this.handleCurrentChange(1);
    },
    indexMethod(index) {
      return (this.page - 1) * this.limit + index + 1;
    },
    handleRead(row) {
      marked(row.id);
      this.handleCurrentChange(1);
    },
    handleDel(row) {
      this.$messageBox.confirm("您确定要删除这条消息？", "提示").then(() => {
        delMsg(row.id).then(() => {
          this.$message.success("删除成功！");
          this.handleCurrentChange(1);
        });
      });
    },
    handleDetail(row) {
      if (row.isRead === 0) {
        marked(row.id).then(() => {
          this.$emit("detail", row);
          this.handleQueryData();
        });
      } else {
        this.$emit("detail", row);
      }
    },
  },
};
</script>

<style lang="less">
.message {
}
</style>
