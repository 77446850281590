<template>
  <div class="my-item">
    <span class="required" v-if="required">*</span>
    <label class="my-item_label">{{}}</label>
    <div class="my-item_content">
      <slot v-if="$slots.default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label", "required"],
};
</script>

<style lang="less">
.my-item {
  width: 100%;
  display: inline-flex;
  line-height: 32px;
  margin-bottom: 15px;

  .required {
    color: #f13232;
    font-size: 14px;
  }

  &_label {
    position: relative;
    width: 35%;
    padding-right: 12px;

    &::after {
      right: 10px;
      position: absolute;
      content: "\uff1a";
    }
  }

  &_content {
    display: inline-block;
    vertical-align: top;
    align-self: flex-start;
  }
}
</style>
