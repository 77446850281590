<template>
  <div class="login-container">
    <el-form
      ref="formData"
      :model="formData"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <div class="title-container">
        <div class="title">注册</div>
      </div>
      <el-form-item prop="phone">
        <span class="svg-container svg-container_login">
          <i class="el-icon-user" />
        </span>
        <el-input
          v-model="formData.phone"
          name="phone"
          type="text"
          auto-complete="on"
          placeholder="手机号码"
        />
      </el-form-item>

      <el-form-item prop="verifyCode">
        <span class="svg-container">
          <i class="el-icon-key" />
        </span>
        <el-input
          v-model="formData.verifyCode"
          name="verifyCode"
          placeholder="验证码"
          class="verify-code-box"
        >
          <el-button
            v-if="show"
            slot="append"
            type="primary"
            :loading="verifyLoading"
            style="width: 100%"
            @click.native.prevent="handleShowVerify"
            >发送验证码
          </el-button>

          <el-button
            v-else
            type="primary"
            slot="append"
            :disabled="true"
            style="width: 96%"
            >{{ count }} s
          </el-button>
        </el-input>
      </el-form-item>

      <div class="flex-box flex-between">
        <el-button
          type="primary"
          :loading="loading"
          style="margin: 10px 0 0; width: 40%; height: 50px"
          @click.native.prevent="handleRegister"
        >
          立即注册
        </el-button>

        <el-button
          :loading="loading"
          style="margin: 10px 0 0; width: 40%; height: 50px"
          @click.native.prevent="handleLogin"
        >
          已有账号去登录
        </el-button>
      </div>
    </el-form>

    <Verify
      ref="verify"
      captchaType="blockPuzzle"
      :imgSize="{ width: '330px', height: '155px' }"
      @success="handleSuccess"
    ></Verify>
  </div>
</template>

<script>
import { sendMsg } from "@/api/login";
import { newUser } from "@/api/register";
import Verify from "@/components/verifition/Verify.vue";

let phoneRegExp = /^1[3|4|5|6|7|8|9]\d{9}$/;
export default {
  name: "Login",
  components: {
    Verify,
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!phoneRegExp.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      formData: {
        phone: "",
        verifyCode: "",
      },
      loginRules: {
        phone: [{ required: true, trigger: "blur", validator: validatePhone }],
        verifyCode: [
          { type: "number", trigger: "blur", message: "验证码为6位数字" },
          { required: false, trigger: "blur", message: "验证码不能为空" },
        ],
      },
      loading: false,
      verifyLoading: false,
      show: true,
      count: "",
      timer: null,
      payVisible: false,
    };
  },
  methods: {
    initTimer() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    handleShowVerify() {
      if (!phoneRegExp.test(this.formData.phone)) {
        this.$message.error("请输入正确的手机号码");
        return;
      }

      this.verifyLoading = true;
      this.$refs.verify.show();
    },
    handleClose() {
      this.verifyLoading = false;
    },
    handleSuccess(data) {
      this.verifyLoading = false;
      sendMsg({
        ...data,
        projectCode: this.formData.phone,
      }).then((res) => {
        if (res.repCode === "0000") {
          this.initTimer();
          this.$message.success("验证码发送成功！");
        }
      });
    },
    handleRegister() {
      this.$refs.formData.validate((valid) => {
        if (valid && !this.loading) {
          this.loading = true;
          newUser(this.formData)
            .then(() => {
              this.loading = false;
              this.$router.push({ path: "/" });
            })
            .catch((error) => {
              this.$notify.error({
                title: "失败",
                message: error.errmsg,
              });

              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleLogin() {
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>

<style lang="less">
@bg: #2d3a4b;
@light_gray: #eee;
@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #eee;
@bg-primary: #247ce5;

/* reset element-ui css */
.login-container {
  .el-input {
    //   display: inline-block;
    height: 47px;
    width: calc(100% - 41px);

    &.verify-code-box {
      .el-input-group__append {
        background-color: @bg-primary;
        border: @bg-primary;
        padding: 0;

        .el-button {
          color: #fff;
          margin: 0;
          width: 100%;
          height: 100%;

          &.is-disabled:hover {
            border-color: transparent;
            background-color: transparent;
          }
        }
      }
    }

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: @light_gray;
      height: 47px;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px @bg inset !important;
        -webkit-box-shadow: 0 0 0px 1000px @bg inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
    margin-bottom: 40px;

    &__content {
      line-height: 45px;
    }
  }
}

.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: @bg;
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    width: 520px;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 0px 5px 0px 15px;
    color: @dark_gray;
    vertical-align: middle;
    width: 20px;
    display: inline-block;
    &_login {
      font-size: 20px;
    }
  }

  .title-container {
    position: relative;
    .title {
      font-size: 26px;
      font-weight: 400;
      color: @light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    font-size: 16px;
    color: @dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
