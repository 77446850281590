import { getPurposeInfo, getRateInfo } from "@/api/config";

export default {
  state: {
    purpose: [],
    rate: [],
    status: false,
  },
  getters: {
    purpose: (state) => state.purpose,
    rate: (state) => state.rate,
    configStatus: (state) => state.status,
  },
  mutations: {
    setPurpose(state, data) {
      state.purpose = data;
    },
    setRate(state, data) {
      state.rate = data;
    },
    setStatus(state, status) {
      state.status = status;
    },
  },
  actions: {
    loadConfig: ({ commit }) => {
      return new Promise((resolve, reject) => {
        const p1 = getPurposeInfo().then((res) => {
          commit("setPurpose", (res.data.purposeList || []).reverse());
        });

        const p2 = getRateInfo().then((res) => {
          commit("setRate", res.data.rateList);
        });

        const ps = [p1, p2];
        Promise.all(ps)
          .then(() => {
            commit("setStatus", true);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
