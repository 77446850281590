import request from "@/common/request";

export function countByUnRead() {
  return request({
    method: "get",
    params: {
      _gp: "pc.msg",
      _mt: "countByUnRead",
    },
  });
}

export function delMsg(id) {
  return request({
    method: "post",
    params: {
      _gp: "pc.msg",
      _mt: "delMsg",
      id,
    },
  });
}

export function listByPage(data) {
  return request({
    method: "get",
    params: {
      _gp: "pc.msg",
      _mt: "listByPage",
      ...data,
    },
  });
}

export function marked(id) {
  return request({
    method: "get",
    params: {
      _gp: "pc.msg",
      _mt: "marked",
      id,
    },
  });
}
