import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import comps from "./components/index";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/style/sidebar.less";
import "./assets/style/base.less";
import "./assets/style/reset.less";
import "./icons"

Vue.use(ElementUI);
Vue.use(comps);
Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();

const token = localStorage.getItem("token") || "";
const tokenKey = localStorage.getItem("tokenKey") || "";
const userInfo = localStorage.getItem("userInfo") || "{}";
if (token && tokenKey && userInfo) {
  store.commit("setToken", token);
  store.commit("setTokenKey", tokenKey);
  store.commit("setUserInfo", JSON.parse(userInfo));
} else {
  store.commit("setStatus", false);
  router.push({
    path: "/login",
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
