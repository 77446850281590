import request from "@/common/request";

export function getPurposeInfo() {
  return request({
    method: "get",
    params: {
      _gp: "pc.config",
      _mt: "getPurposeInfo",
    },
  });
}

export function getRateInfo() {
  return request({
    method: "get",
    params: {
      _gp: "pc.config",
      _mt: "getRateInfo",
    },
  });
}
