import request from "@/common/request";
// import Qs from "qs";

export function loginByAccount(phone, password) {
  const data = {
    phone,
    password,
  };
  return request({
    method: "post",
    params: {
      _gp: "pc.login",
      _mt: "loginByAccount",
      ...data,
    },
  });
}

export function loginByCode(phone, verifyCode) {
  const data = {
    phone,
    verifyCode,
  };
  return request({
    method: "post",
    params: {
      _gp: "pc.login",
      _mt: "loginByCode",
      ...data,
    },
  });
}

// 获取验证图片  以及token
export function reqGet(data) {
  return request({
    method: "post",
    baseURL: "/captcha",
    url: "/get",
    data,
  });
}

// 滑动或者点选验证
export function reqCheck(data) {
  return request({
    method: "post",
    baseURL: "/captcha",
    url: "/check",
    data,
  });
}

// 发送短信验证码
export function sendMsg(data) {
  return request({
    method: "post",
    baseURL: "/captcha",
    url: "/verify",
    data,
  });
}

export function logout() {
  return request({
    method: "post",
    params: {
      _gp: "pc.login",
      _mt: "logout",
    },
  });
}
