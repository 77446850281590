import axios from "axios";
import { Message, MessageBox, Loading } from "element-ui";
import store from "@/store";
import { errMsg } from "./errorMsg";

let loading = null;

// create an axios instance
const service = axios.create({
  baseURL: "/pc", // api 的 base_url
  timeout: 15000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (store.getters.token) {
      if (config.hideLoading !== true) {
        loading = Loading.service({ fullscreen: true });
      }

      // 让每个请求携带token-- ['ACCESSTTOKEN']为自定义key 请根据实际情况自行修改
      config.headers["ACCESSTOKEN"] = store.getters.token;
      config.headers["ACCESSTOKENKEY"] = store.getters.tokenKey;
    }
    return config;
  },
  (error) => {
    loading && loading.close();
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    loading && loading.close();
    const res = response.data;

    // 已退出登录或在异地登录
    if ([10001, 10009].indexOf(res.errno) > -1) {
      MessageBox.alert(errMsg(res.errno), "错误", {
        confirmButtonText: "确定",
        type: "error",
      }).then(() => {
        store.dispatch("FedLogOut").then(() => {
          location.reload();
        });
      });
      return Promise.reject("error");
    }

    if (res.repCode === "0000") {
      return res;
    }

    if (res.errno !== 200 || res.repCode) {
      Message({
        message: errMsg(res.errno || res.repCode),
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(res);
    } else {
      return response.data;
    }
  },
  (error) => {
    loading && loading.close();
    if (error && error.response && error.response.data) {
      let errData = error.response.data;

      Message({
        message: errMsg(errData.errno),
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(errData);
    }
    return Promise.reject(error);
  }
);

export default service;
