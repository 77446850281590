<template>
  <div id="app">
    <router-view />

    <el-dialog
      center
      width="600px"
      title="打借条"
      :visible.sync="visible"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <add-receipt
        v-if="visible"
        @close="handleClose"
        @submit="handleSubmit"
        @view="tempVisible = true"
      ></add-receipt>
    </el-dialog>

    <el-dialog
      center
      width="400px"
      title="借条二维码"
      :visible.sync="visibleQrcode"
      :close-on-click-modal="false"
      @close="visibleQrcode = false"
    >
      <div class="qrcode-box">
        <div id="qrCode" class="flex-box"></div>
        <div class="time-box text-center">
          请将此二维码截图发送给{{ details.type === 1 ? "借款人" : "出借人" }}
        </div>
      </div>
    </el-dialog>

    <el-dialog
      center
      title="协议模板"
      width="1000px"
      :visible.sync="tempVisible"
      @close="tempVisible = false"
    >
      <contract-template></contract-template>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import AddReceipt from "@/views/jietiao/addReceipt";
import ContractTemplate from "@/views/jietiao/contract-template";

export default {
  components: {
    AddReceipt,
    ContractTemplate,
  },
  data() {
    return {
      visible: false,
      tempVisible: false,
      visibleQrcode: false,
      details: {},
    };
  },
  mounted() {
    this.$bus.$on("addReceipt", () => {
      this.visible = true;
    });
    this.$bus.$on("showCode", (data) => {
      this.handleSubmit(data);
    });
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
    handleSubmit(data) {
      this.details = data;
      this.visible = false;
      this.visibleQrcode = true;
      this.$nextTick(() => {
        let container = document.getElementById("qrCode");
        container.innerHTML = "";
        new QRCode(container, {
          text: `http://pingzhengyun.veyd.cn/ysd/contractController/contractInfo?id=${data.id}`,
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

.qrcode-box {
  padding: 15px 0;
  box-sizing: border-box;

  #qrCode {
    & > img {
      padding: 6px;
      background: #f5f5f5;
      border-radius: 6px;
    }
  }

  .time-box {
    font-size: 16px;
    margin-top: 15px;
    letter-spacing: 1px;
  }
}
</style>
