import request from "@/common/request";

export function getPayInfo(userId) {
  return request({
    method: "get",
    params: {
      _gp: "pc.pay",
      _mt: "getPayInfo",
      userId,
    },
  });
}

export function prePay(data) {
  return request({
    method: "post",
    params: {
      _gp: "pc.pay",
      _mt: "prePay",
      ...data,
    },
  });
}

export function queryOrderStatus(orderNo) {
  return request({
    method: "post",
    hideLoading: true,
    params: {
      _gp: "pc.pay",
      _mt: "queryOrderStatus",
      orderNo,
    },
  });
}
