import request from "@/common/request";
// import Qs from 'qs';

export function getUserInfo() {
  return request({
    method: "get",
    params: {
      _gp: "pc.member",
      _mt: "getUserInfo",
    },
  });
}

export function updatePwd({ pwd, newPwd, userId }) {
  return request({
    method: "post",
    params: {
      _gp: "pc.member",
      _mt: "updatePwd",
      pwd,
      newPwd,
      userId,
    },
  });
}

export function forgetPwd({ phone, code, newPwd, userId }) {
  return request({
    method: "post",
    params: {
      _gp: "pc.member",
      _mt: "forgetPwd",
      phone,
      code,
      newPwd,
      userId,
    },
  });
}

export function boundPhone({ phone, code, newPhone, userId }) {
  return request({
    method: "post",
    params: {
      _gp: "pc.member",
      _mt: "boundPhone",
      phone,
      code,
      newPhone,
      userId,
    },
  });
}

/**
 * 完善个人信息
 * @param {*} data
 * @returns
 */
export function registerByPerfect(data) {
  return request({
    method: "post",
    params: {
      _gp: "pc.register",
      _mt: "registerByPerfect",
      ...data,
    },
  });
}
