<template>
  <div class="contract-info">
    <el-descriptions :column="2">
      <el-descriptions-item label="出借人">
        {{ (details.lender || {}).name }}
      </el-descriptions-item>
      <el-descriptions-item label="借款人">
        {{ (details.borrower || {}).name }}
      </el-descriptions-item>
      <el-descriptions-item label="借款金额">
        {{ details.borrowAmount }}
      </el-descriptions-item>
      <el-descriptions-item label="年化利率">
        {{ details.yearTransfer }}%
      </el-descriptions-item>
      <el-descriptions-item label="借款用途">
        {{ details.purposeLoan }}
      </el-descriptions-item>
      <el-descriptions-item label="当前状态">
        {{ getItemByKey(details.status, statusList, "key").name }}
      </el-descriptions-item>
      <el-descriptions-item label="创建日期">
        {{ formatDate(details.createTime) }}
      </el-descriptions-item>
      <el-descriptions-item label="借款日期">
        {{ formatDate(details.borrowTime) }}
      </el-descriptions-item>
      <el-descriptions-item label="还款日期">
        {{ formatDate(details.refundTime) }}
      </el-descriptions-item>
    </el-descriptions>

    <div class="operate-box">
      <template v-if="details.uid == userInfo.id">
        <template v-if="details.status == 0">
          <el-button
            size="small"
            type="primary"
            class="operation"
            @click="handleOperate(6)"
          >
            撤销合同
          </el-button>

          <el-button
            size="small"
            type="primary"
            class="operation"
            @click="handleOperate('code')"
          >
            查看二维码
          </el-button>
        </template>
        <template v-if="details.status == 1">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(7)"
            >
              申请延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(9)"
            >
              申请还款
            </el-button>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              确认已还款
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 4">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(7)"
            >
              申请延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(9)"
            >
              申请还款
            </el-button>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              确认已还款
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 5">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(7)"
            >
              申请延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(9)"
            >
              申请还款
            </el-button>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              确认已还款
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 7">
          <template v-if="details.type !== 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(8)"
            >
              同意延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(5)"
            >
              驳回延期
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 8">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(7)"
            >
              申请延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(9)"
            >
              申请还款
            </el-button>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              确认已还款
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 9">
          <template v-if="details.type !== 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(4)"
            >
              驳回还款
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              同意还款
            </el-button>
          </template>
        </template>
      </template>

      <template v-else>
        <template v-if="details.status == 0">
          <el-button
            size="small"
            type="primary"
            class="operation"
            @click="handleOperate(3)"
          >
            驳回合同
          </el-button>

          <el-button
            size="small"
            type="primary"
            class="operation"
            @click="handleOperate(1)"
          >
            确认合同
          </el-button>
        </template>
        <template v-if="details.status == 1">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              确认已还款
            </el-button>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(7)"
            >
              申请延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(9)"
            >
              申请还款
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 4">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              确认已还款
            </el-button>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(7)"
            >
              申请延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(9)"
            >
              申请还款
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 5">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              确认已还款
            </el-button>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(7)"
            >
              申请延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(9)"
            >
              申请还款
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 7">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(8)"
            >
              同意延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(5)"
            >
              驳回延期
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 8">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              确认已还款
            </el-button>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(7)"
            >
              申请延期
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(9)"
            >
              申请还款
            </el-button>
          </template>
        </template>
        <template v-if="details.status == 9">
          <template v-if="details.type == 2">
            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(4)"
            >
              驳回还款
            </el-button>

            <el-button
              size="small"
              type="primary"
              class="operation"
              @click="handleOperate(2)"
            >
              同意还款
            </el-button>
          </template>
        </template>
      </template>
    </div>

    <el-dialog
      center
      width="500px"
      :modal="false"
      :visible.sync="visibleDelay"
      :close-on-click-modal="false"
    >
      <apply-delay
        v-if="visibleDelay"
        :data="details"
        @close="visibleDelay = false"
        @submit="handleDelaySubmit"
      ></apply-delay>
    </el-dialog>
  </div>
</template>

<script>
import { getJietiaoInfo, operateJietiao } from "@/api/jietiao";
import { formatDate, getItemByKey } from "@/common/utils";
import { personTypes, statusList } from "@/common/const";
import ApplyDelay from "./apply-delay.vue";

export default {
  props: ["id"],
  components: {
    ApplyDelay,
  },
  data() {
    return {
      statusList,
      personTypes,
      details: {},
      visibleDelay: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.loadDetails();
  },
  methods: {
    getItemByKey,
    formatDate(date) {
      return formatDate(date, "y-m-d");
    },
    loadDetails() {
      getJietiaoInfo(this.id).then((res) => {
        this.details = res.data || {};
      });
    },
    handleDelaySubmit() {
      this.visibleDelay = false;
      this.$emit("submit");
    },
    handleOperate(status) {
      if (status === "code") {
        this.$bus.$emit('showCode', this.details);
      } else if (status !== 7) {
        this.$messageBox
          .prompt("请输入安全密码", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputType: "password",
            inputPattern: /^\d{6}$/,
            inputErrorMessage: "请输入6位数字密码",
            customClass: "confirm-prompt",
            closeOnClickModal: false,
            center: true,
          })
          .then(({ value }) => {
            let param = {
              status: status,
              id: this.id,
              password: value,
            };

            operateJietiao(param).then(() => {
              this.$emit("submit");
              this.$message.success("操作成功！");
            });
          });
      } else {
        this.visibleDelay = true;
      }
    },
  },
};
</script>

<style lang="less">
.contract-info {
  .operate-box {
    margin-top: 15px;
    text-align: center;
  }
}

.confirm-prompt {
  .el-input {
    width: 80%;
  }
}
</style>
