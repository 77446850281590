import { MessageBox } from "element-ui";
import { loginByAccount, loginByCode, logout } from "@/api/login";
import { getUserInfo } from "@/api/user";
import router from "@/router";

export default {
  state: {
    userInfo: {},
    token: null,
    tokenKey: null,
    status: false,
  },
  getters: {
    token: (state) => state.token,
    tokenKey: (state) => state.tokenKey,
    userInfo: (state) => state.userInfo,
    userStatus: (state) => state.status,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
      localStorage.setItem("token", token);
    },
    setTokenKey: (state, tokenKey) => {
      state.tokenKey = tokenKey;
      localStorage.setItem("tokenKey", tokenKey);
    },
    setUserInfo: (state, data) => {
      state.userInfo = data;
      localStorage.setItem("userInfo", JSON.stringify(data));
      state.status = true;
    },
    setStatus: (state, status) => {
      state.status = status;
    },
  },
  actions: {
    // 用户名登录
    LoginByUsername({ commit, dispatch }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByAccount(userInfo.phone, userInfo.password)
          .then((response) => {
            const { accessToken, id } = response.data;
            commit("setToken", accessToken);
            commit("setTokenKey", id);
            commit("setUserInfo", response.data);
            dispatch("GetUserInfo")
              .then(() => {
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 验证码登录
    LoginByCode({ commit, dispatch }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByCode(userInfo.phone, userInfo.verifyCode)
          .then((response) => {
            const { accessToken, id } = response.data;
            commit("setToken", accessToken);
            commit("setTokenKey", id);
            commit("setUserInfo", response.data);
            dispatch("GetUserInfo")
              .then(() => {
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((response) => {
            if (response && response.data) {
              const data = response.data;
              commit("setUserInfo", data);

              if (data.check !== 3) {
                const notice = {
                  0: "请前往进行实名认证",
                  1: "您的实名认证在审核中，请耐心等待",
                  2: "您的实名认证审核失败，请完善信息后再试一次",
                };
                let message = notice[data.check];
                MessageBox.confirm(message, "提示", {
                  confirmButtonText: "确定",
                  type: "warning",
                }).then(() => {
                  if (data.check !== 1) {
                    router.push({
                      path: "/identify",
                    });
                  }
                });
                reject(response);
              }
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ dispatch }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            router.push({
              path: "/login",
            });
            dispatch("FedLogOut");
            resolve();
          })
          .catch((error) => {
            if (error && error.errno === 10012) {
              dispatch("FedLogOut");
              router.push({
                path: "/login",
              });
            }
            reject(error);
          });
      });
    },
    // 前端登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("setToken", "");
        commit("setUserInfo", {});
        commit("setStatus", false);
        resolve();
      });
    },
  },
};
