<template>
  <div class="pay">
    <div class="qrcode-box">
      <div class="item-title">请扫码支付</div>
      <div id="qrCode" class="flex-box" ref="qrCode"></div>
      <div class="time-box text-center" v-if="paying">
        支付倒计时{{ formatTime(timeclock) }}s
      </div>
    </div>
    <div class="price-box margin-top-sm">
      <div class="item-title">会员期效</div>
      <template v-for="price in payInfos">
        <my-data-panel
          unit="￥"
          checkedClass="color-white"
          icon="ooo"
          class="cursor-pointer margin-sm"
          :key="price.id"
          :title="price.name"
          :num="numFormat(price.price)"
          :checked="formData.piceId === price.id"
          @click="handleCheck(price, 'piceId')"
        >
          <div class="bg-gray footer color-white" slot="footer">
            {{ price.remark }}
          </div>
        </my-data-panel>
      </template>
    </div>
    <div class="way-box margin-top-sm">
      <div class="item-title">支付方式</div>
      <template v-for="type in payTypes">
        <my-data-panel
          :key="type.id"
          :title="type.name"
          :checked="formData.payType === type.id"
          checkedClass="color-white"
          class="cursor-pointer margin-sm"
          @click="handleCheck(type, 'payType')"
        >
          <img class="way-box_icon" :src="type.icon" alt="" slot="icon" />
          <div class="bg-gray footer color-white" slot="footer">
            {{ type.remark }}
          </div>
        </my-data-panel>
      </template>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { payTypes } from "@/common/const";
import { prePay, queryOrderStatus, getPayInfo } from "@/api/pay";

let timer = null;

export default {
  data() {
    return {
      payTypes,
      payInfos: [],
      formData: {},
      paying: false,
      orderNo: "",
      timeclock: 5 * 60,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    formData: {
      handler() {
        this.timeclock = 5 * 60;
        this.getPayCode();
      },
      deep: true,
    },
  },
  created() {
    getPayInfo(this.userInfo.uid || this.userInfo.id).then((res) => {
      this.payInfos = res.data.payInfos;

      this.formData = {
        payType: "ALIPAY",
        wayId: res.data.payWays[0].id,
        piceId: res.data.payInfos[0].id,
        userId: this.userInfo.uid || this.userInfo.id,
      };
    });
  },
  methods: {
    numFormat(num) {
      return (num / 100).toFixed(2);
    },
    formatTime(seconds) {
      let s = seconds % 60;
      let m = (seconds - s) / 60;
      return `${m}:${s}`;
    },
    handleCheck(data, key) {
      this.$set(this.formData, key, data.id);
    },
    getPayCode() {
      prePay(this.formData).then((res) => {
        this.orderNo = res.data.orderNo;
        timer && clearInterval(timer);
        this.initTimer();

        this.$nextTick(() => {
          document.getElementById("qrCode").innerHTML = "";
          new QRCode(this.$refs.qrCode, {
            text: res.data.payUrl,
            width: 100,
            height: 100,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        });
      });
    },
    initTimer() {
      this.paying = true;
      timer = setInterval(() => {
        if (this.timeclock > 0) {
          if (this.timeclock % 3 === 0) {
            this.queryPayStatus();
          }
          this.timeclock--;
        } else {
          clearInterval(timer);
          timer = null;
          this.timeclock = 5 * 60;
          this.paying = false;
        }
      }, 1000);
    },
    queryPayStatus() {
      queryOrderStatus(this.orderNo).then((res) => {
        if (res.data.status === 1) {
          clearInterval(timer);
          timer = null;
          this.paying = false;
          this.$emit("paid");
        }
      });
    },
  },
};
</script>

<style lang="less">
.pay {
  .price-box {
    .my-data-panel {
      &_body {
        justify-content: center !important;

        .data-box_title {
          font-weight: 600;
        }

        .data-box_data {
          color: #ff9800;
        }
      }
    }
  }

  .my-data-panel {
    display: inline-block;
    width: 260px;
  }
  .margin-sm {
    margin: 15px;
  }

  .margin-top-sm {
    margin-top: 15px;
  }

  .item-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
  }

  .footer {
    padding: 6px 15px;
  }

  .way-box_icon {
    height: 50px;
  }

  .qrcode-box {
    padding: 15px 0;
    box-sizing: border-box;

    #qrCode {
      & > img {
        padding: 6px;
        background: #f5f5f5;
        border-radius: 6px;
      }
    }
  }

  .time-box {
    margin: 10px 0;
  }
}
</style>
