import Vue from "vue";
import Vuex from "vuex";
import userInfo from "./modules/userInfo";
import config from "./modules/config";
import tagsView from "./modules/tagsView";
import sidebar from "./modules/sidebar";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userInfo,
    config,
    tagsView,
    sidebar,
  },
});
