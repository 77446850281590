<template>
  <div class="member" style="width: 800px; margin: 0 auto">
    <el-form
      inline
      ref="userInfo"
      label-width="120px"
      style="width: 100%"
      :model="userInfo"
    >
      <el-form-item label="姓名：" prop="name">
        <div class="field-half" v-text="userInfo.name"></div>
      </el-form-item>
      <el-form-item label="手机号码：" prop="phone">
        <div class="field-half" v-text="userInfo.phone"></div>
      </el-form-item>
      <el-form-item label="QQ 号：" prop="qq">
        <div class="field-half" v-text="userInfo.qq"></div>
      </el-form-item>
      <el-form-item label="微信号：" prop="wechat">
        <div class="field-half" v-text="userInfo.wechat"></div>
      </el-form-item>
      <el-form-item label="身份证号：" prop="idCard">
        <div class="field-half" v-text="userInfo.idCard"></div>
      </el-form-item>
      <el-form-item label="截止日期：" prop="expirationTime">
        <div class="field-half" v-text="userInfo.expirationTime"></div>
      </el-form-item>
      <el-form-item label="实名认证：" prop="check">
        <div
          class="field"
          v-text="getItemByKey(userInfo.check, userStatusList).name"
        ></div>
      </el-form-item>
      <el-form-item
        v-if="userInfo.check === 2"
        label="失败原因："
        prop="rejectMes"
      >
        <div class="field" v-text="userInfo.rejectMes"></div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getItemByKey } from "@/common/utils";
import { userStatusList } from "@/common/const";

export default {
  name: "Member",
  data() {
    return {
      userStatusList,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    console.log(this.userInfo);
  },
  methods: {
    getItemByKey,
  },
};
</script>

<style lang="less">
.member {
  .el-form {
    &-item {
      margin-bottom: 25px;

      &__label {
        font-weight: 600;
      }

      .field {
        width: 530px;

        &-half {
          width: 200px;
        }
      }

      .el-button {
        width: 100px;
      }
    }
  }
}
</style>
