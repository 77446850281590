import request from "@/common/request";

export function newUser({ phone, verifyCode }) {
  return request({
    method: "post",
    params: {
      _gp: "pc.register",
      _mt: "newUser",
      phone,
      verifyCode,
    },
  });
}
