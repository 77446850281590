import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import Login from "@/views/account/login";
import Register from "@/views/account/register";
import menus from "./menus";

Vue.use(VueRouter);

const routes = [
  ...menus,
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/member",
    name: "Member",
    component: import(/* webpackChunkName: "member" */ "@/views/member"),
  },
  {
    path: "/identify",
    name: "Identify",
    component: () =>
      import(/* webpackChunkName: "identify" */ "@/views/member/identify"),
  },
  {
    path: "/pay",
    name: "Pay",
    component: () => import(/* webpackChunkName: "pay" */ "@/views/pay"),
  },
  {
    path: "*",
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 登录、注册页面可以不判断是否登录，其他页面均需要判断
  const excludes = ["Login", "Register"];
  if (!store.getters.userStatus && excludes.indexOf(to.name) < 0) {
    // 当前页面不是login时跳转
    if (from.name !== "Login") {
      router.push({ path: "/login" });
    }
  } else {
    next();
  }
});

export default router;
