const Err_Map = {
  10000: "系统未知异常",
  10001: "用户尚未登录",
  10002: "参数校验失败",
  10003: "支付参数异常！",
  10004: "attention please！系统内部数据不一致 注意！！",
  10005: "Api 不再支持调用",
  10006: "管理员尚未登录",
  10007: "申请延期失败！",
  10008: "管理员权限不足",
  10009: "账号异地登录！",
  10010: "未实名认证！",
  10011: "账号未续租！",
  10012: "账号已到期！",
  11006: "用户名或密码不正确，请重新输入！",
  80001: "自己不能向自己发起借条！",
  80002: "新增借条失败！",
  80003: "操作借条失败！",
  80004: "查无此人！",
  80005: "借条已被确认过！",
  88888: "没有权限！",

  70008: "填写失败！",
  70009: "密码错误！",
  20001: "没有查询到此用户！",
  "0001": "使用填充密码解密时，输入长度必须是16的倍数",
  "0011": " 参数不能为空",
  6110: "验证码已失效，请重新获取",
  6111: " 验证失败",
  6112: " 获取验证码失败,请联系管理员",
  6113: " 底图未初始化成功，请检查路径",
  6201: " get接口请求次数超限，请稍后再试!",
  6206: " 无效请求，请重新获取验证码",
  6202: " 接口验证失败数过多，请稍后再试",
  6204: " check接口请求次数超限，请稍后再试",
  9999: " 服务器内部异常",
};

export function errMsg(key) {
  return Err_Map[key] || "系统未知异常";
}
