<template>
  <div class="add-receipt" v-loading="!configStatus">
    <el-form
      ref="formData"
      :model="formData"
      :rules="AddRules"
      label-width="140px"
    >
      <el-form-item label="我是：" prop="type">
        <el-radio-group v-model="formData.type">
          <el-radio
            border
            v-for="type in personTypes"
            :key="type.id"
            :label="type.id"
            >{{ type.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="对方姓名：" prop="person">
        <el-input
          maxlength="20"
          style="width: 350px"
          placeholder="请输入对方姓名"
          v-model="formData.person"
        ></el-input>
      </el-form-item>
      <el-form-item label="借款金额：" prop="borrowAmount">
        <el-input
          max="200000"
          maxlength="6"
          style="width: 350px"
          placeholder="请输入借款金额"
          v-model.number="formData.borrowAmount"
        ></el-input>
      </el-form-item>
      <el-form-item label="借款日期：" prop="borrowTime">
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="—"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 350px"
          :picker-options="pickerOptions"
          @change="handleDateChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="年化利率：" prop="yearTransfer">
        <el-select
          v-model="formData.yearTransfer"
          style="width: 350px"
          placeholder="请选择年化利率"
        >
          <el-option
            v-for="item in rates"
            :key="item.id"
            :label="item.rate + ' %'"
            :value="item.rate"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="借款用途：" prop="purposeLoan">
        <el-select
          v-model="formData.purposeLoan"
          style="width: 350px"
          placeholder="请选择借款用途"
        >
          <el-option
            v-for="item in purposes"
            :key="item.code"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="协议模板：" prop="template">
        <el-button type="text" @click="handleViewTemplate">点击查看</el-button>
      </el-form-item>

      <el-form-item label="重要声明：">
        <div style="width: 350px; line-height: 30px; margin-top: 5px">
          信息填写错误会使合同作废，请确认信息的准确性；发起合同或确认合同前请确认合同双方已达成实质协议，合同一经对方确认立即生效。
        </div>
      </el-form-item>
    </el-form>

    <div class="text-center mt-lg">
      <el-button size="small" type="primary" @click="handleSubmit">
        提交
      </el-button>
      <el-button size="small" @click="handleCancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import { personTypes } from "@/common/const";
import { newJietiao } from "@/api/jietiao";

export default {
  data() {
    return {
      personTypes,
      date: [],
      formData: {
        type: 1,
        person: "",
        purposeLoan: "",
        yearTransfer: "",
        borrowAmount: "",
        borrowTime: "",
        refundTime: "",
      },
      AddRules: {
        type: [
          { required: true, message: "请选择我的身份", trigger: "change" },
        ],
        person: [
          { required: true, message: "请输入对方姓名", trigger: "change" },
        ],
        borrowAmount: [
          { required: true, message: "请输入借款金额", trigger: "change" },
          { type: "number", message: "请输入正确的数字", trigger: "change" },
        ],
        yearTransfer: [
          { required: true, message: "请选择年化利率", trigger: "change" },
        ],
        purposeLoan: [
          { required: true, message: "请选择借款用途", trigger: "change" },
        ],
        borrowTime: [
          { required: true, message: "请选择借款日期", trigger: "change" },
        ],
        refundTime: [
          { required: true, message: "请选择还款日期", trigger: "change" },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    purposes() {
      return this.$store.getters.purpose;
    },
    rates() {
      return this.$store.getters.rate;
    },
    configStatus() {
      return this.$store.getters.configStatus;
    },
  },
  created() {
    this.$store.dispatch("loadConfig");
  },
  methods: {
    handleDateChange([borrowTime, refundTime]) {
      Object.assign(this.formData, { borrowTime, refundTime });
    },
    handleViewTemplate() {
      this.$emit("view");
    },
    handleSubmit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          newJietiao(this.formData).then((res) => {
            this.$message.success("打借条成功，等待对方确认！");
            this.$emit("submit", res.data);
          });
        }
      });
    },
    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less">
.add-receipt {
}
</style>
