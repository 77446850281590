<template>
  <div class="applay-delay">
    <el-form :model="formDelay" label-width="140px">
      <el-form-item label="延期日期：" class="mt-md">
        <el-date-picker
          v-model="formDelay.delayTime"
          type="date"
          placeholder="选择日期"
          style="width: 260px"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="operate-box mt-sm">
      <el-button size="small" type="primary" @click="handleSubmitDelay">
        确认
      </el-button>
      <el-button size="small" @click="$emit('close')">取消</el-button>
    </div>
  </div>
</template>

<script>
import { delayJietiao } from "@/api/jietiao";

export default {
  props: ["data"],
  data() {
    return {
      formDelay: {
        delayTime: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
    };
  },
  created() {
    console.log(this.data);
    this.$set(this.formDelay, "id", this.data.id);
  },
  methods: {
    handleSubmitDelay() {
      if (!this.formDelay.delayTime) {
        this.$message.error("请选择延期日期！");
      } else {
        delayJietiao(this.formDelay).then(() => {
          this.$emit("submit");
        });
      }
    },
  },
};
</script>

<style lang="less">
.applay-delay {
}
</style>
