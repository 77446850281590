<template>
  <div>
    <div class="navbar">
      <hamburger
        :toggle-click="toggleSideBar"
        :is-active="sidebar.opened"
        class="hamburger-container"
      />

      <breadcrumb class="breadcrumb-container" />

      <div class="operate-box right-menu" style="margin-right: 20px">
        <span class="cursor-pointer" @click="handleOperate('addReceipt')">
          打借条
        </span>

        <el-badge :value="msgCount" :max="99" class="msg-num">
          <span class="cursor-pointer" @click="handleOperate('msg')">
            <i class="el-icon-s-comment color-white"></i>
            消息
          </span>
        </el-badge>

        <template v-for="operation in operations">
          <el-dropdown
            :key="operation.index"
            class="cursor-pointer"
            style="margin-left: 20px"
            @command="handleOperate"
          >
            <span class="el-dropdown-link">
              <i :class="['el-icon-' + operation.icon, 'color-white']"></i>
              {{ operation.name }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in operation.items"
                :key="item.index"
                :command="item.index"
                >{{ item.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </div>
    </div>

    <el-dialog
      center
      width="1100px"
      :append-to-body="false"
      :visible.sync="visibleMsg"
      :close-on-click-modal="false"
    >
      <Message v-if="visibleMsg" @detail="handleDetails" />
    </el-dialog>

    <el-dialog
      center
      width="800px"
      title="借条信息"
      :append-to-body="false"
      :visible.sync="visibleMsgDetail"
      :close-on-click-modal="false"
    >
      <contract-info
        v-if="visibleMsgDetail"
        :id="details.jietiaoId"
        @submit="handleOperateSubmit"
      />
    </el-dialog>

    <el-dialog
      center
      title="个人信息"
      width="900px"
      :visible.sync="visibleUser"
      :close-on-click-modal="false"
    >
      <Member />
    </el-dialog>

    <el-dialog
      center
      width="600px"
      :title="title"
      :visible.sync="visible"
      :append-to-body="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <el-form
        ref="formData"
        :model="formData"
        :rules="formRules"
        label-width="140px"
      >
        <el-form-item
          :label="visibleBind ? '原手机号：' : '手机号码：'"
          prop="phone"
          v-if="visibleBind || visibleForgetPwd"
        >
          <el-input
            style="width: 300px"
            auto-complete="on"
            :placeholder="'请输入' + (visibleBind ? '原手机号' : '手机号码')"
            v-model="formData.phone"
          ></el-input>
        </el-form-item>

        <el-form-item label="新手机号：" prop="newPhone" v-if="visibleBind">
          <el-input
            style="width: 300px"
            auto-complete="off"
            placeholder="请输入新手机号"
            v-model="formData.newPhone"
          ></el-input>
        </el-form-item>

        <el-form-item prop="pwd" label="原密码：" v-if="visibleNewPwd">
          <el-input
            style="width: 300px"
            :type="passwordType"
            v-model="formData.pwd"
            placeholder="请输入原密码"
          >
            <span class="show-pwd" slot="suffix" @click="showPwd">
              <i class="el-icon-view" />
            </span>
          </el-input>
        </el-form-item>

        <el-form-item
          prop="newPwd"
          label="新密码："
          v-if="visibleNewPwd || visibleForgetPwd"
        >
          <el-input
            :type="passwordType"
            style="width: 300px"
            v-model="formData.newPwd"
            placeholder="请输入新密码"
          >
            <span class="show-pwd" slot="suffix" @click="showPwd">
              <i class="el-icon-view" />
            </span>
          </el-input>
        </el-form-item>

        <el-form-item
          prop="confirmPwd"
          label="确认密码："
          v-if="visibleNewPwd || visibleForgetPwd"
        >
          <el-input
            :type="passwordType"
            style="width: 300px"
            v-model="formData.confirmPwd"
            placeholder="请确认密码"
          >
            <span class="show-pwd" slot="suffix" @click="showPwd">
              <i class="el-icon-view" />
            </span>
          </el-input>
        </el-form-item>

        <el-form-item
          label="验证码："
          prop="code"
          v-if="visibleBind || visibleForgetPwd"
        >
          <el-input
            style="width: 300px"
            v-model="formData.code"
            placeholder="请输入验证码"
            class="verify-code-box"
          >
            <el-button
              v-if="show"
              slot="append"
              type="primary"
              :loading="verifyLoading"
              style="width: 100%"
              @click.native.prevent="
                handleShowVerify(
                  visibleBind ? formData.newPhone : formData.phone
                )
              "
            >
              发送验证码
            </el-button>

            <el-button
              v-else
              type="primary"
              slot="append"
              :disabled="true"
              style="width: 96%"
              >{{ count }} s
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button size="small" type="primary" @click="handleSubmit">
          提交
        </el-button>
        <el-button size="small" @click="handleClose">取消</el-button>
      </template>
    </el-dialog>

    <Verify
      ref="verify"
      captchaType="blockPuzzle"
      :imgSize="{ width: '330px', height: '155px' }"
      @success="handleSuccess"
    ></Verify>
  </div>
</template>

<script>
import menus from "@/router/menus";
import { sendMsg } from "@/api/login.js";
import { countByUnRead } from "@/api/msg.js";
import { updatePwd, forgetPwd, boundPhone } from "@/api/user";
import { operations } from "@/common/const";
import Verify from "@/components/verifition/Verify.vue";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import Member from "@/views/member";
import Message from "@/views/jietiao/message.vue";
import ContractInfo from "@/views/jietiao/contract-info.vue";

let phoneRegExp = /^1[3|4|5|6|7|8|9]\d{9}$/;
let pwdRegExp = /^\d{6}$/;
export default {
  components: {
    Breadcrumb,
    Hamburger,
    Member,
    Verify,
    Message,
    ContractInfo,
  },
  data() {
    const pwdValidator = (rule, value, callback) => {
      if (!pwdRegExp.test(value)) {
        callback(new Error("请输入6位数字密码"));
      }
      callback();
    };

    return {
      visibleMsg: false,
      msgCount: 0,
      details: {},
      visibleMsgDetail: false,
      menus,
      operations,
      title: "",
      visible: false,
      visibleUser: false,
      visibleBind: false,
      visibleForgetPwd: false,
      visibleNewPwd: false,
      formData: {
        phone: "",
        code: "",
        newPhone: "",
        pwd: "",
        newPwd: "",
        confirmPwd: "",
      },
      passwordType: "password",
      verifyLoading: false,
      show: true,
      count: "",
      timer: null,
      formRules: {
        phone: [
          {
            required: true,
            trigger: "change",
            message: "请输入" + (this.visibleBind ? "原手机号" : "手机号码"),
          },
        ],
        newPhone: [
          { required: true, trigger: "change", message: "请输入新手机号" },
        ],
        pwd: [{ required: true, trigger: "change", message: "请输入密码" }],
        newPwd: [
          { required: true, trigger: "change", validator: pwdValidator },
        ],
        confirmPwd: [
          { required: true, trigger: "change", validator: pwdValidator },
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }],
      },
    };
  },
  computed: {
    active() {
      return this.$route.fullPath;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    sidebar() {
      return this.$store.getters.sidebar;
    },
  },
  created() {
    this.loadMsgInfo();
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("toggleSideBar");
    },
    loadMsgInfo() {
      countByUnRead().then((res) => {
        this.msgCount = res.data || 0;
      });
    },
    handleDetails(data) {
      this.loadMsgInfo();
      this.details = data;
      this.visibleMsgDetail = true;
    },
    handleOperateSubmit() {
      this.loadMsgInfo();
      this.visibleMsgDetail = false;
    },
    handleSelect(key) {
      if (this.active !== key) {
        this.$router.push({
          path: key,
        });
      }
    },
    handleOperate(key) {
      this.formData = {
        userId: this.userInfo.id,
        phone: this.userInfo.phone || "",
        code: "",
        newPhone: "",
        pwd: "",
        newPwd: "",
        confirmPwd: "",
      };

      switch (key) {
        case "userinfo":
          this.visibleUser = true;
          break;
        case "resetpwd":
          this.visible = true;
          this.projectCode = "2";
          this.visibleNewPwd = true;
          this.title = "修改密码";
          break;
        case "forgetpwd":
          this.visible = true;
          this.projectCode = "5";
          this.visibleForgetPwd = true;
          this.title = "忘记密码";
          break;
        case "bindPhone":
          this.visible = true;
          this.projectCode = "3";
          this.visibleBind = true;
          this.title = "绑定手机号";
          break;
        case "logout":
          this.$store.dispatch("LogOut");
          break;

        case "msg":
          this.visibleMsg = true;
          break;

        default:
          this.$bus.$emit(key);
          break;
      }
    },
    initTimer() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    },
    handleShowVerify(phone) {
      console.log(phone);
      if (!phoneRegExp.test(phone)) {
        this.$message.error("请输入正确的手机号码");
        return;
      }

      this.verifyLoading = true;
      this.$refs.verify.show();
    },
    handleSuccess(data) {
      this.verifyLoading = false;
      sendMsg({
        ...data,
        projectCode: this.formData.newPhone || this.formData.phone,
        captchaType: this.projectCode,
      }).then(() => {
        this.initTimer();
        this.$message.success("验证码发送成功！");
      });
    },
    handleSubmit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          if (this.visibleBind) {
            let { phone, code, newPhone, userId } = this.formData;
            boundPhone({ phone, code, newPhone, userId }).then(() => {
              this.handleClose();
            });
          } else if (this.visibleForgetPwd) {
            if (this.formData.confirmPwd !== this.formData.newPwd) {
              this.$message.error("新密码与确认密码不一致");
              return;
            }
            let { phone, code, newPwd, userId } = this.formData;
            forgetPwd({ phone, code, newPwd, userId }).then(() => {
              this.handleClose();
            });
          } else if (this.visibleNewPwd) {
            if (this.formData.confirmPwd !== this.formData.newPwd) {
              this.$message.error("新密码与确认密码不一致");
              return;
            }
            let { pwd, newPwd, userId } = this.formData;
            updatePwd({ pwd, newPwd, userId }).then(() => {
              this.handleClose();
            });
          }
        }
      });
    },
    handleClose() {
      this.visible = false;
      this.visibleBind = false;
      this.visibleForgetPwd = false;
      this.visibleNewPwd = false;
    },
  },
};
</script>

<style lang="less">
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .breadcrumb-container {
    float: left;
  }
  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }
  .right-menu {
    float: right;
    height: 100%;
    margin-right: 30px;
    &:focus {
      outline: none;
    }
    .menu-box {
      min-width: 480px;
    }

    &.operate-box {
      .msg-num {
        .el-badge__content.is-fixed {
          top: 10px;
        }
      }
    }

    .el-form-item {
      margin-bottom: 30px;
    }

    .show-pwd {
      font-size: 16px;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
